export default {
  props: {
    listKey: String,
    row: Object,
    name: String,
    index: Number,
    config: Object,
  },
  methods: {
    async patch(value) {
      try {
        const result = await this._patchRequest(value)
        await this._afterUpdateSuccess(result) // 標準更新後的動作
        await this.afterUpdateSuccess(result) // 開放給自訂更新後的動作
        this.$snotify.success(null, this.$t('save.successfully') )
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t('error.unexpect'),
          this.$t('save.failure')
        )
      }
    },
    async _patchRequest(value) {
      // 列表table data自訂patch請求操作
      if(typeof this.config.patchRequest === 'function') {
        return this.config.patchRequest({
          api: this.$api,
          config: this.config,
          row: this.row,
          value,
        })
      }

      return this.listApi.patchApi(value, this.row, this.config)
    },
    async _afterUpdateSuccess(result) {
      if(this.config.refreshAfterUpdateSuccess) {
        this.listAction.index()
        return
      }

      const data = result[this.name]
      this._updateDataColumn(data)
    },
    _updateDataColumn(data) {
      let row = window.eagleLodash.cloneDeep(this.row)
      row[this.name] = data
      this.$store.dispatch(`list/${this.listKey}/updateRow`, {
        row,
        rowIndex: this.index,
      })
    },
    afterUpdateSuccess(result) {
      if(typeof this.config.afterUpdateSuccess != 'function') return
      this.config.afterPatchSuccessfully(result)
    },
  },
  computed: {
    disabled() {
      if(!this.hasWritePermission) return true
      return false
    },
    hasWritePermission() {
      return this.$store.getters[`list/${this.listKey}/hasWritePermission`]
    },
    target() {
      return this.row[this.targetKey]
    },
    targetKey() {
      return this.$store.getters[`list/${this.listKey}/targetKey`]
    },
    listAction() {
      return this.$store.getters[`list/${this.listKey}/action`]
    },
    listApi() {
      return this.$store.getters[`list/${this.listKey}/api`]
    },
    linkTarget() {
      if(typeof this.config.linkTarget === 'function') {
        return this.config.linkTarget(this.row)
      }

      return null
    },
    href() {
      if(typeof this.config.href === 'function') {
        return this.config.href(this.row)
      }

      return null
    },
    route() {
      if(typeof this.config.route === 'function') {
        return this.config.route(this.row)
      }

      return null
    },
    copyText() {
      if(typeof this.config.copyText === 'function') {
        return this.config.copyText(this.row)
      }
      return this.text
    },
    text() {
      if(typeof this.config.text === 'function') {
        return this.config.text(this.row)
      }
      return this.itemData
    },
    isHtml() {
      return this.config.isHtml === true
    },
    copyable() {
      return this.config.copyable === true
    },
    itemData() {
      return window.eagleLodash.get(this.row, this.name)
    },
    activeTooltip() {
      if(!this.tooltipContent) return null
      return this.config.tooltip != false
    },
    tooltipContent() {
      if(typeof this.config.tooltipContent === 'function') {
        return this.config.tooltipContent(this.row)
      }
      return this.itemData
    },
  },
}
